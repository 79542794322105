import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import {
  faLocation,
  faMobileAlt,
  faStar,
  faGift,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";

import "./Footer.css";

const Footer = () => {
  return (
    <div>
      <footer
        id="or-footer-2"
        className="or-footer-section-2 text-start"
        data-background="https://html.themexriver.com/organio/assets/img/bg/bg-footer-top-f3.png"
        style={{
          backgroundImage:
            'url("https://html.themexriver.com/organio/assets/img/bg/bg-footer-top-f3.png")',marginTop:'111px'
        }}
      >
        <div className="or-footer-newslatter-1">
          <div className="container">
            <div
              className="or-footer-newslatter-content headline  position-relative"
              data-background="assets/img/bg/bg-mailchimp.jpg"
              style={{
                backgroundImage: 'url("assets/img/bg/bg-footer-bottom-f3.png")',
              }}
            >
              <span className="or-footer-newslatter-img">
                <img
                  src="https://html.themexriver.com/organio/assets/img/bg/mailchimp-image1.png"
                  alt=""
                />
              </span>
              <div className="or-footer-newslatter-text-form">
                <h3>Subscribe to our Newsletter:</h3>
                <div className="or-footer-newslatter-form position-relative">
                  <form action="#">
                    <input
                      type="text"
                      placeholder="type your mail address..."
                    />
                  </form>
                  <button>
                    Subscribe <i className="far fa-long-arrow-right"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="or-footer-widget-wrapper-1">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-6">
                <div className="or-footer-widget headline pera-content ul-li-block">
                  <div className="or-logo-widget">
                    <a href="#">
                      <img
                        src="https://html.themexriver.com/organio/assets/img/logo/logo3.png"
                        alt=""
                      />
                    </a>
                    <p>
                      We work with a passion of taking challenges and creating
                      new ones in advertising sector.{" "}
                    </p>
                    <div className="footer-social">
                      <a href="#">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-twitter"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-dribbble"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-behance"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="or-footer-widget  headline pera-content ul-li-block">
                  <div className="or-menu-widget">
                    <h2 className="widget-title">Links</h2>
                    <ul>
                      <li>
                        <a href="index.html">Home</a>
                      </li>
                      <li>
                        <a href="service.html">Services</a>
                      </li>
                      <li>
                        <a href="about.html">About us</a>
                      </li>
                      <li>
                        <a href="shop.html">Shop</a>
                      </li>
                      <li>
                        <a href="blog.html">News</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="or-footer-widget headline pera-content ul-li-block">
                  <div className="or-contact-widget">
                    <h2 className="widget-title">Official info:</h2>
                    <ul>
                      <li>
                      <FontAwesomeIcon icon={faLocation} />{" "}
                        <span>
                          380 St Kilda Road, Melbourne VIC 3004, Australia{" "}
                        </span>
                      </li>
                      <li>
                        <i className="fas fa-envelope"></i>{" "}
                        <span> info@envato.com </span>{" "}
                      </li>
                    </ul>
                    <span className="title">Open Hours: </span>
                    <p>Mon - Sat: 8 am - 5 pm, Sunday: CLOSED</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="or-footer-widget headline pera-content ul-li-block">
                  <div className="or-gallery-widget">
                    <h2 className="widget-title">Photo Gallery</h2>
                    <ul className="zoom-gallery">
                      <li>
                        <a
                          href="https://html.themexriver.com/organio/assets/img/gallery/gl1.jpg"
                          data-source="https://html.themexriver.com/organio/assets/img/gallery/gl1.jpg"
                        >
                          <img src="https://html.themexriver.com/organio/assets/img/gallery/gl1.jpg" alt="" />
                        </a>
                      </li>
                      <li>
                        <a
                          href="assets/img/gallery/gl2.jpg"
                          data-source="assets/img/gallery/gl2.jpg"
                        >
                          <img src="assets/img/gallery/gl2.jpg" alt="" />
                        </a>
                      </li>
                      <li>
                        <a
                          href="assets/img/gallery/gl3.jpg"
                          data-source="assets/img/gallery/gl3.jpg"
                        >
                          <img src="assets/img/gallery/gl3.jpg" alt="" />
                        </a>
                      </li>
                      <li>
                        <a
                          href="assets/img/gallery/gl4.jpg"
                          data-source="assets/img/gallery/gl4.jpg"
                        >
                          <img src="assets/img/gallery/gl4.jpg" alt="" />
                        </a>
                      </li>
                      <li>
                        <a
                          href="assets/img/gallery/gl5.jpg"
                          data-source="assets/img/gallery/gl4.jpg"
                        >
                          <img src="assets/img/gallery/gl5.jpg" alt="" />
                        </a>
                      </li>
                      <li>
                        <a
                          href="assets/img/gallery/gl6.jpg"
                          data-source="assets/img/gallery/gl4.jpg"
                        >
                          <img src="assets/img/gallery/gl6.jpg" alt="" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="or-footer-copyright"
          data-background="assets/img/bg/bg-footer-bottom-f3.png"
          style={{
            backgroundImage: 'url("assets/img/bg/bg-footer-bottom-f3.png")',
          }}
        >
          <div className="container">
            <div className="or-footer-copyright-wrapper  d-flex justify-content-between align-items-center">
              <div className="or-footer-copyright">
                @ Copyright 2021. HTML template by <a href="#">Themexriver</a>
              </div>
              <div className="footer-payment">
                <img src="assets/img/bg/f3-payment.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
