import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import {
    faTag,
    faMobileAlt,
    faStar,
    faGift,
    faSearch
} from "@fortawesome/free-solid-svg-icons";

const DeliverySection = () => {
    return (
        <div>
   <div class="delivery-section bg-main-600">
      <div class="  container-lg">
         <div class="delivery position-relative rounded-16 bg-main-600 p-16 flex-align gap-16 flex-wrap z-1">
            {/* <img src="https://react.marketpro.wowtheme7.com/assets/images/bg/delivery-bg.png" alt="" class="position-absolute inset-block-start-0 inset-inline-start-0 z-n1 w-100 h-100" /> */}
            <div class="row align-items-center">
               <div class="col-md-3 d-md-block d-none">
                  <div class="delivery__man text-center"><img src="https://react.marketpro.wowtheme7.com/assets/images/thumbs/delivery-man.png" alt="" /></div>
               </div>
               <div class="col-md-5 col-sm-7">
                  <div class="text-center">
                     <h4 class="text-white mb-8">We Delivery on Next Day from 10:00 AM to 08:00 PM</h4>
                     <p class="text-white">For Orders starts from $100</p>
                     <a class="mt-16 btn btn-main-two fw-medium d-inline-flex align-items-center rounded-pill gap-8" tabindex="0" href="/shop">Shop Now<span class="icon text-xl d-flex"><i class="ph ph-arrow-right"></i></span></a>
                  </div>
               </div>
               <div class="col-md-4 col-sm-5 d-sm-block d-none"><img src="https://react.marketpro.wowtheme7.com/assets/images/thumbs/special-snacks-img.png" className="kala" alt="" /></div>
            </div>
         </div>
      </div>
   </div>
</div>
    );
};

export default DeliverySection;
