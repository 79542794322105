import React, { useState } from "react";
import product from "../Component/img/product.png";
import Navbar from "../Component/Navbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faMobileAlt,
  faStar,
  faGift,
  faBaby,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";

function Cart() {
  // State to manage quantities for each row (use an array of quantities)
  const [quantities, setQuantities] = useState([1, 1, 1, 1]); // Assuming 4 items

  // Function to increase quantity for a specific item
  const increaseQty = (index) => {
    setQuantities((prevQuantities) =>
      prevQuantities.map((qty, i) => (i === index ? qty + 1 : qty))
    );
  };

  // Function to decrease quantity for a specific item
  const decreaseQty = (index) => {
    setQuantities((prevQuantities) =>
      prevQuantities.map((qty, i) => (i === index && qty > 1 ? qty - 1 : qty))
    );
  };

  // Render a row for each item
  const renderRow = (index) => (
    <div key={index}>
      <div className="row align-items-center text-start mt-2 mx-0 p-0">
        <div className="w-20p m-0 p-1 text-center">
          <img src={product} className="img-fluid ig1" alt="Product" />
        </div>
        <div className="w-50p m-0 p-1">
          <div className="se1">Snacks & Munchies</div>
          <div className="se2">Lay's Indian's Magic...</div>
          <div className="se1">Rs. 300.00</div>
        </div>
        <div className="w-30p text-center m-0 p-1">
          <div id="qty" className="d-flex justify-content-center">
            <button
              onClick={() => decreaseQty(index)}
              className="btn btn-sm btn-outline-secondary awa"
            >
              -
            </button>
            <span className=" awaspn">{quantities[index]}</span>
            <button
              onClick={() => increaseQty(index)}
              className="btn btn-sm btn-outline-secondary awa"
            >
              +
            </button>
          </div>
        </div>
      </div>
      <hr />
    </div>
  );

  return (
    <>
      <nav className="navbar sticky-top hide-on-desktop">
        <div className="navbar-container">
          <div className="navbar-search">
            <div className="search-wrapper py-1 d-flex justify-content-between">
              <span className="spnn1">
                <FontAwesomeIcon icon={faArrowLeft} className="icnn1" /> My Cart
              </span>
              <span className="spnn2">
                <i class="bi bi-basket d-flex m-0 h5 text-white"></i>
              </span>
            </div>
          </div>
        </div>
      </nav>

      <Navbar />

      <div className="text-start mt-3  ">
        <div class="osahan-my-account-page border-secondary-subtle overflow-hidden">
          <div class="row row-cols-xl-1 row-cols-lg-1 row-cols-md-1 row-cols-1 g-0">
            <div class="col shop-list-page border-bottom">
              <div class="d-flex align-items-center bg-transparent border-0 rounded-0 h-100 osahan-card-list pe-3">
                <a href="#">
                  <img src={product} alt="" class="card-img-top" />
                </a>
                <div class="card-body pt-0">
                  <p class="card-text text-muted mb-1 small">
                    Snaks &amp; Munchies
                  </p>
                  <h6 class="card-title fw-bold text-truncate">
                    Lay's Indian's Magic Masala
                  </h6>
                  <p class="text-dark small m-0">$45 X 2 = $90</p>
                  <p class="text-muted small m-0 strik">$50 X 2 = $100</p>
                  {/* <p class="text-muted small m-0"><span className="strik">$45</span><span className="mx-2">$39</span>  </p> */}
                </div>
                <div class="card-footer bg-transparent border-0 d-flex align-items-end justify-content-between pt-0 gap-4 text-end">
                  <div class="input-group border rounded overflow-hidden value">
                    <input
                      type="button"
                      value="-"
                      class="button-minus btn btn-light btn-sm border-end col"
                      data-field="quantity"
                    />
                    <input
                      type="text"
                      value="1"
                      name="quantity"
                      class="quantity-field form-control form-control-sm col text-center shadow-none border-0"
                    />
                    <input
                      type="button"
                      value="+"
                      class="button-plus btn btn-light btn-sm border-start col"
                      data-field="quantity"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="col shop-list-page border-bottom">
              <div class="d-flex align-items-center bg-transparent border-0 rounded-0 h-100 osahan-card-list pe-3">
                <a href="#">
                  <img src={product} alt="" class="card-img-top" />
                </a>
                <div class="card-body pt-0">
                  <p class="card-text text-muted mb-1 small">
                    Snaks &amp; Munchies
                  </p>
                  <h6 class="card-title fw-bold text-truncate">
                    Kurkure Masala Much Crips
                  </h6>
                  <p class="text-muted small m-0">$45 $39</p>
                </div>
                <div class="card-footer bg-transparent border-0 d-flex align-items-end justify-content-between pt-0 gap-4 text-end">
                  <div class="input-group border rounded overflow-hidden value">
                    <input
                      type="button"
                      value="-"
                      class="button-minus btn btn-light btn-sm border-end col"
                      data-field="quantity"
                    />
                    <input
                      type="text"
                      value="1"
                      name="quantity"
                      class="quantity-field form-control form-control-sm col text-center shadow-none border-0"
                    />
                    <input
                      type="button"
                      value="+"
                      class="button-plus btn btn-light btn-sm border-start col"
                      data-field="quantity"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="col shop-list-page border-bottom">
              <div class="d-flex align-items-center bg-transparent border-0 rounded-0 h-100 osahan-card-list pe-3">
                <a href="#">
                  <img src={product} alt="" class="card-img-top" />
                </a>
                <div class="card-body pt-0">
                  <p class="card-text text-muted mb-1 small">
                    Snaks &amp; Munchies
                  </p>
                  <h6 class="card-title fw-bold text-truncate">
                    Bingo Tedhe Medhe Masala Tadka
                  </h6>
                  <p class="text-muted small m-0">$45 $39</p>
                </div>
                <div class="card-footer bg-transparent border-0 d-flex align-items-end justify-content-between pt-0 gap-4 text-end">
                  <div class="input-group border rounded overflow-hidden value">
                    <input
                      type="button"
                      value="-"
                      class="button-minus btn btn-light btn-sm border-end col"
                      data-field="quantity"
                    />
                    <input
                      type="text"
                      value="1"
                      name="quantity"
                      class="quantity-field form-control form-control-sm col text-center shadow-none border-0"
                    />
                    <input
                      type="button"
                      value="+"
                      class="button-plus btn btn-light btn-sm border-start col"
                      data-field="quantity"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="">
        <div class="d-flex align-items-center justify-content-between w-100 mb-3">
           
          <a
            href="#"
            class="btn btn-danger m-2 p-2 px-2 d-flex align-items-center rounded-pill text-link text-decoration-none btn-sm text-start"
             
          >
     Add Note{" "} 
          </a>
        </div>


        
      </div>
             
          </div>
        </div>
      </div>
      
      


{/* invoice */}

<div>
<h2 class="accordion-header" id="headingOne">
                <button
                  class="accordion-button bg-white text-dark p-3"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  <span class="fw-bold">Invoice</span>
                </button>
              </h2>


<div class="accordion-body px-3 pb-3 pt-0">
                  <div class="d-flex justify-content-between align-items-center pb-1">
                    <div class="text-muted">Item total</div>
                    <div class="text-dark">₹1295</div>
                  </div>
                  <div class="d-flex justify-content-between align-items-center py-1">
                    <div class="text-muted">Discount</div>
                    <div class="text-dark">₹5</div>
                  </div>
                  <div class="d-flex justify-content-between align-items-center py-1 text-info">
                    <div>Sub-Total</div>
                    <div>₹50</div>
                  </div>
                  <div class="d-flex justify-content-between align-items-center h6 m-0 pt-2">
                    <div class="fw-bold">To pay</div>
                    <div class="fw-bold text-danger">₹245</div>
                  </div>
                </div>
</div>


      <div class="p-3 bg-white mt-auto footer1">





        {/* <div class="bg-white border border-secondary-subtle rounded-4 position-relative overflow-hidden mb-3">
          <div class="accordion" id="accordionExample">
            <div class="accordion-item border-0">
              <h2 class="accordion-header" id="headingOne">
                <button
                  class="accordion-button bg-white text-dark p-3"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  <span class="fw-bold">Invoice</span>
                </button>
              </h2>
              <div
                id="collapseOne"
                class="accordion-collapse text-dark collapse"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body px-3 pb-3 pt-0">
                  <div class="d-flex justify-content-between align-items-center pb-1">
                    <div class="text-muted">Item total</div>
                    <div class="text-dark">₹1295</div>
                  </div>
                  <div class="d-flex justify-content-between align-items-center py-1">
                    <div class="text-muted">Discount</div>
                    <div class="text-dark">₹5</div>
                  </div>
                  <div class="d-flex justify-content-between align-items-center py-1 text-info">
                    <div>Sub-Total</div>
                    <div>₹50</div>
                  </div>
                  <div class="d-flex justify-content-between align-items-center h6 m-0 pt-2">
                    <div class="fw-bold">To pay</div>
                    <div class="fw-bold text-danger">₹245</div>
                  </div>
                </div>
                <div class="bg-success text-white px-3 py-3">
                  <span>
                    <i class="bi bi-check-circle-fill text-white"></i>
                  </span>
                  &nbsp; Woohoo! You saved ₹50 on this order
                </div>
              </div>
            </div>
          </div>



        </div> */}




        <div class="mt-3">
          <a
            href="checkout.html"
            class="btn btn-danger fw-bold d-flex align-items-center justify-content-between py-3 px-4 w-100 rounded-4"
          >
            3 items · $130{" "}
            <span>
              Proceed To Checkout<i class="icofont-double-right ms-1"></i>
            </span>
          </a>
        </div>
      </div>
    </>
  );
}

export default Cart;
